import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Cookie from '../Components/Cookie';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import { RatingLogos } from '../Components/ShortSummaryLogos';
import AanvragenAndPostcodeCheck from '../Components/AanvragenAndPostcodeCheck';
// #DB1F6A

export default function GeneralConditions() {
  return (
    <Fragment>
        
        <Header activePage="/algemene-voorwaarden.html" />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			<img alt="twee vrouwen die met elkaar praten" className="header-bg-clip bg-cover" src="/assets/images/intakekraamtijd-header-sm.jpg" />
		</div>

		<div className="md:container-fluid md:bg-ervaringen-header md:h-[32rem] md:bg-no-repeat md:bg-right md:bg-[#666666]  md:header-bg-clip md:bg-contain">
			<div className="container mx-auto py-4 lg:py-7 flex justify-start">
				<div className="md:w-[65%] lg:w-[45%] md:bg-black md:bg-opacity-40 md:box-border md:rounded-lg md:z-10 md:relative">
					<h1 className="text-2xl lg:text-4xl md:text-white p-4 lg:py-4 lg:px-8 lg:pb-4 font-ro-sans font-bold">Algemene voorwaarden</h1>
					<div className="px-4 pb-2 lg:px-8 lg:pb-8 md:text-white text-lg">
						<p>
                            Deze algemene voorwaarden zijn opgesteld teneinde een zo duidelijk en volledig mogelijk kader te bieden 
                            voor de relatie tussen Kraamzorg Mama (hierna: "kraamzorgaanbieder") en de cliënte, zowel vóór, 
                            tijdens als na de kraamzorgperiode. Door op deze voorwaarden voort te bouwen en deze uit te breiden, 
                            wordt beoogd de rechten en plichten van alle betrokken partijen uitvoerig te omschrijven, mogelijke misverstanden 
                            te voorkomen en een zorgvuldige, veilige en professionele kraamzorgverlening te waarborgen. Deze voorwaarden 
                            vloeien voort uit de geldende (beroeps)normen, protocollen, kwaliteitsrichtlijnen en relevante regelgeving.
						</p>
                        <div className="hidden md:block">
							<RequestButton className="hidden md:mt-8 md:inline-block" />
						</div>
                    </div>
                    
				</div>
			</div>
		</div>



        <div className="md:container-fluid mt-16">
			<div className="container mx-auto justify-end">
                <div className="p-4 lg:p-0 flex flex-col gap-4 lg:w-2/3">


                    
                    <article>
                        <h3 className="font-bold">ARTIKEL 1 - DEFINITIES</h3>
                            In deze algemene voorwaarden wordt verstaan onder:
                            <ol className="list-decimal pl-6">
                                <li><strong>Cliënte:</strong> De natuurlijke persoon die kraamzorg ontvangt of zal ontvangen van Kraamzorg Mama. Voor de bevalling wordt de zwangere hiermee bedoeld en na de bevalling de kraamvrouw. Indien de cliënte minderjarig is, geldt tevens dat een vertegenwoordiger (ouder of voogd) moet instemmen met de overeenkomst.</li>
                                <li><strong>Kraamzorgaanbieder:</strong> De (rechts)persoon, in dit geval Kraamzorg Mama, die kraamzorg verleent die kan worden gefinancierd door de zorgverzekering, al dan niet in combinatie met particulier gefinancierde kraamzorg en/of aanvullende diensten. De kraamzorgaanbieder kan ook gebruikmaken van onderaannemers, mits deze aan dezelfde (kwaliteits)eisen voldoen en onder verantwoordelijkheid van de hoofdaannemer werken.</li>
                                <li><strong>Verloskundige:</strong> Een zelfstandig medisch beroepsbeoefenaar (verloskundige of verloskundig actieve huisarts) die de zwangere vrouw en haar partner gedurende zwangerschap en bevalling begeleidt. De verloskundige is het centrale aanspreekpunt voor de medische aspecten rond zwangerschap en bevalling.</li>
                                <li><strong>Kraamverzorgende:</strong> De natuurlijke persoon die kraamzorg en eventueel partusassistentie verleent onder medische verantwoordelijkheid en in overleg met de verloskundige. Zij is bevoegd, bekwaam en handelt volgens professionele standaarden.</li>
                                <li><strong>Kraamzorg:</strong> Zorg, ondersteuning, instructie en voorlichting aan de cliënte en de pasgeborene, gericht op het bevorderen van een gezonde en veilige start voor moeder en kind. Dit omvat hygiënische, verpleegkundige en huishoudelijke taken, voorlichting over voeding, verzorging en gezondheid, alsmede instructie en begeleiding van de partner en andere gezinsleden.</li>
                                <li><strong>Minimale kraamzorg:</strong> Het landelijk vastgestelde minimum aantal uren kraamzorg (exclusief partusassistentie), doorgaans 24 uren verdeeld over acht dagen.</li>
                                <li><strong>Indicatiestelling:</strong> Het bepalen van het aantal en de aard van de te verlenen kraamzorguren aan de hand van het Landelijk Indicatieprotocol Kraamzorg (LIP).</li>
                                <li><strong>Landelijk Indicatieprotocol Kraamzorg (LIP):</strong> Een landelijk protocol dat kwaliteitsnormen beschrijft voor verantwoorde kraamzorg en de hoeveelheid benodigde kraamzorguren vaststelt op basis van de situatie, behoeften en gezondheidstoestand van cliënte en pasgeborene.</li>
                                <li><strong>Inschrijving:</strong> Het schriftelijke, telefonische of digitale verzoek van de cliënte aan Kraamzorg Mama om kraamzorg te leveren, voorafgaand aan het tot stand komen van de overeenkomst.</li>
                                <li><strong>Overeenkomst:</strong> De tussen de cliënte en Kraamzorg Mama gesloten afspraak over het leveren van kraamzorg, inclusief eventuele aanvullende diensten, waarop deze algemene voorwaarden van toepassing zijn.</li>
                                <li><strong>Intake:</strong> Een persoonlijk of telefonisch gesprek tussen een vertegenwoordiger van Kraamzorg Mama en de cliënte (uiterlijk vóór de 34e week van de zwangerschap), waarin de aard, omvang en vorm van de kraamzorg en eventuele aanvullende diensten worden besproken, alsmede de verwachtingen van beide partijen.</li>
                                <li><strong>Praktijkbegeleider:</strong> De natuurlijke persoon die een kraamverzorgende in opleiding of stagiaire begeleidt op de werkplek, verantwoordelijk voor deskundige begeleiding, toezicht en kwaliteitsbewaking.</li>
                                <li><strong>JGZ-overdracht:</strong> De overdracht van relevante gegevens uit de kraamperiode (over de cliënte, de pasgeborene, de gezinssituatie, de bevalling en het verloop van de kraamzorgperiode) aan de Jeugdgezondheidszorg, zodat de nazorg voor moeder en kind optimaal kan worden voortgezet.</li>
                                <li><strong>Incident:</strong> Ieder niet-beoogd of onvoorzien voorval tijdens het kraamzorgproces, dat direct of op termijn merkbare gevolgen kan hebben voor de gezondheid, veiligheid of het welzijn van de cliënte en/of de pasgeborene.</li>
                                <li><strong>Schriftelijk:</strong> Onder schriftelijk wordt tevens verstaan communicatie per e-mail of langs elektronische weg, mits de informatie reproduceerbaar en toegankelijk blijft voor latere raadpleging.</li>
                                <li><strong>Elektronische weg:</strong> Het overbrengen of opslaan van gegevens via een website, internet of e-mail op een zodanige wijze dat deze betrouwbaar, toegankelijk en reproduceerbaar zijn.</li>
                            </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 2 - TOEPASSELIJKHEID</h3>
                        <ol className="list-decimal pl-6">    
                            <li>Deze algemene voorwaarden zijn van toepassing op iedere overeenkomst die wordt gesloten tussen Kraamzorg Mama en de cliënte.</li>
                            <li>Deze algemene voorwaarden beschrijven de rechten en plichten van zowel Kraamzorg Mama (de kraamzorgaanbieder) als de cliënte.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 3 - BEKENDMAKING ALGEMENE VOORWAARDEN</h3>
                        <ol className="list-decimal pl-6">
                            <li>Kraamzorg Mama overhandigt deze algemene voorwaarden aan de cliënte voorafgaand aan of bij de totstandkoming van de overeenkomst en licht deze mondeling toe op verzoek.</li>
                            <li>De algemene voorwaarden zijn via de website van Kraamzorg Mama in te zien en kunnen op verzoek per e-mail worden toegestuurd. Indien de overeenkomst elektronisch tot stand komt, worden de algemene voorwaarden op zodanige wijze elektronisch ter beschikking gesteld dat de cliënte deze kan opslaan en voor toekomstige raadpleging toegankelijk zijn.</li>
                            <li>Indien de overeenkomst niet elektronisch tot stand komt, kunnen de algemene voorwaarden alsnog digitaal verstrekt worden, maar alleen met uitdrukkelijke instemming van de cliënte. Bij inschrijving via telefoon of digitaal geldt dat de cliënte door inschrijving akkoord gaat met de algemene voorwaarden, bevestigd middels een e-mail met verwijzing naar deze voorwaarden.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 4 - AFWIJKING VAN DE ALGEMENE VOORWAARDEN</h3>
                        <p>Afwijkingen van deze algemene voorwaarden zijn alleen geldig als zij schriftelijk zijn overeengekomen. Afwijkingen mogen niet in het nadeel zijn van de cliënte, de pasgeborene of de kraamzorgaanbieder. Het streven is te allen tijde dat de overeengekomen kraamzorg kwalitatief en zorgvuldig wordt uitgevoerd binnen de kaders van deze voorwaarden.</p>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 5 - DUIDELIJKE INFORMATIE</h3>
                        <ol className="list-decimal pl-6">
                            <li>Kraamzorg Mama zorgt ervoor dat de cliënte voorafgaand aan de overeenkomst voldoende informatie heeft om weloverwogen te kiezen voor de kraamzorgaanbieder, waaronder informatie over het aanbod, de kwaliteit, tarieven en voorwaarden.</li>
                            <li>
                                In deze informatie staat in ieder geval vermeld:<br />
                                a. Dat de overeenkomst tot stand komt zodra Kraamzorg Mama de inschrijving accepteert;<br />
                                b. Dat de cliënte tot 14 dagen na acceptatie kosteloos het recht heeft de overeenkomst ongedaan te maken;<br />
                                c. Eventuele voorbehouden ten aanzien van het kunnen leveren van de afgesproken kraamzorg (bijvoorbeeld beschikbaarheid in bepaalde perioden).<br />
                            </li>
                            <li>Kraamzorg Mama informeert de cliënte gedurende de looptijd van de overeenkomst voldoende over relevante aangelegenheden rond de uitvoering van de kraamzorg, waaronder wijzigingen in planning, inzet van personeel of bijzondere omstandigheden.</li>
                            <li>Kraamzorg Mama vergewist zich ervan dat de cliënte de verstrekte informatie heeft begrepen, bijvoorbeeld door een expliciete bevestiging per telefoon, e-mail of door het aankruisen van een desbetreffend vakje bij online inschrijving.</li>
                        </ol>
                    </article>


                    <article>
                        <h3 className="font-bold">ARTIKEL 6 - DE OVEREENKOMST</h3>
                        <ol className="list-decimal pl-6">
                            <li>De door de cliënte gedane inschrijving (schriftelijk, telefonisch of digitaal) geldt als verzoek tot het verlenen van kraamzorg. De overeenkomst komt tot stand zodra Kraamzorg Mama deze inschrijving schriftelijk of per e-mail bevestigt.</li>
                            <li>De cliënte heeft vanaf het moment van sluiten van de overeenkomst 14 dagen de tijd om deze zonder kosten te ontbinden, tenzij de kraamzorg binnen deze termijn al op nadrukkelijk verzoek van de cliënte is begonnen.</li>
                            <li>Indien de cliënte jonger is dan 18 jaar, is de instemming van een vertegenwoordiger vereist. De financiële garantstelling voor minderjarigen ligt bij hun vertegenwoordiger.</li>
                            <li>   
                                De overeenkomst bevat onder andere:<br />
                                a. Een verwijzing naar het LIP voor de aard en omvang van de kraamzorg;<br />
                                b. Voorwaarden over het leveren van geïndiceerde uren kraamzorg en het minimaal gegarandeerde aantal uren, afhankelijk van het tijdstip van inschrijving;<br />
                                c. Een omschrijving van mogelijke voorbehouden inzake de leveringsmogelijkheden;<br />
                                d. Afspraken over aanvullende kraamzorg en diensten en de daaraan verbonden kosten (worden als addendum vastgelegd);<br />
                                e. De landelijk bepaalde eigen bijdrage over geleverde uren kraamzorg en verwijzing naar de regels inzake declaratie hiervan;<br />
                                f. Een regeling betreffende toestemming voor gebruik van gegevens van cliënte en pasgeborene (zoals kwaliteitsmetingen, controles door zorgverzekeraars en overdracht aan JGZ);<br />
                                g. Eventuele annuleringskostenregeling;<br />
                                h. Een bepaling dat wijziging van de overeenkomst alleen schriftelijk en na overleg plaatsvindt;<br />
                                i. Een verwijzing naar deze algemene voorwaarden en hun toepasselijkheid.
                            </li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 7 - AFWIJKING VAN DE OVEREENKOMST</h3>
                        <ol className="list-decimal pl-6">
                            <li>Afwijking van de overeengekomen kraamzorguren is alleen mogelijk in onderling overleg en wordt schriftelijk vastgelegd. Afwijken van de minimaal voorgeschreven kraamzorg is niet mogelijk.</li>
                            <li>Indien de cliënte minder uren kraamzorg afneemt dan overeengekomen of om andere redenen de overeenkomst wijzigt, wordt de eigen bijdrage uitsluitend berekend over het werkelijk afgenomen aantal uren. Door ondertekening van het urenregistratieformulier verklaart de cliënte zich akkoord met het aantal daadwerkelijk geleverde uren.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 8 - DE INTAKE</h3>
                        <ol className="list-decimal pl-6">
                            <li>Tijdens de intake worden de indicatiestelling en de omvang van de kraamzorg besproken, evenals de procedure voor (her)indicatie en gevolgen van voortijdige beëindiging. Tevens worden de wensen van de cliënte en de mogelijkheden van aanvullende kraamzorg of diensten in kaart gebracht.</li>
                            <li>
                                Voor of tijdens de intake ontvangt de cliënte schriftelijke informatie over: <br />
                                a. De taakverdeling en verantwoordelijkheden tussen verloskundige en kraamverzorgende; <br />
                                b. Sleutelbeheer; <br />
                                c. Benodigde voorzieningen inzake arbeidsomstandigheden (bijv. bedverhogers, hygiënemateriaal); <br />
                                d. Verbod op het gebruik van privévoertuigen van cliënte of partner door de kraamverzorgende;<br />
                                e. Parkeerbeleid en eventuele parkeerkosten;<br />
                                f. Privacybeleid en omgang met persoonsgegevens;<br />
                                g. Medicatiebeleid;<br />
                                h. Inzet en toestemming voor kraamverzorgenden in opleiding of stagiaires;<br />
                                i. Eventuele annuleringskostenregelingen;<br />
                                j. Consequenties van arbeidstijden en cao voor de inzet van kraamverzorgenden;<br />
                                k. Schaderegeling voor door medewerkers veroorzaakte schade.
                            </li>
                            <li>De gemaakte afspraken worden schriftelijk vastgelegd, bijvoorbeeld in een addendum bij de overeenkomst.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 9 - HET KRAAMZORGPLAN</h3>
                        <ol className="list-decimal pl-6">
                            <li>Aan het begin van de kraamzorg stelt de kraamverzorgende, in samenspraak met de cliënte, een kraamzorgplan op gebaseerd op de indicatiestelling uit het LIP.</li>
                            <li>In dit plan worden doelen, afspraken, dagelijkse werkzaamheden en evaluatiemomenten vastgelegd, afgestemd op de wensen, gewoonten en omstandigheden van de cliënte, de pasgeborene en het gezin.</li>
                            <li>Het kraamzorgplan vermeldt ook welke gezinsleden of mantelzorgers betrokken worden en welke ondersteuning, instructie en voorlichting zij krijgen.</li>
                            <li>Indien de overeengekomen kraamzorg niet conform het kraamzorgplan kan worden verleend, wordt de cliënte direct geïnformeerd. In overleg wordt het plan bijgesteld. Hetzelfde geldt als de cliënte op enig moment besluit de zorg niet (geheel) conform het plan te willen ontvangen.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 10 - PRIVACY EN GEDRAGSCODES</h3>
                        <ol className="list-decimal pl-6">
                            <li>Op de verwerking en bescherming van persoonsgegevens van de cliënte en de pasgeborene is de geldende privacyregeling van toepassing. Kraamzorg Mama handelt conform het privacybeleid en gaat zorgvuldig met persoonsgegevens om.</li>
                            <li>Medewerkers van Kraamzorg Mama roken niet in het huis van de cliënte of in de nabijheid van de cliënte of de pasgeborene.</li>
                            <li>Medewerkers regelen nooit geldzaken namens de cliënte en maken geen gebruik van financiële middelen (creditcard, bankpas) van de cliënte.</li>
                            <li>Medewerkers nemen geen cadeaus, giften of spullen van cliënten aan.</li>
                            <li>Medewerkers maken geen gebruik van auto’s van de cliënte of haar huisgenoten.</li>
                            <li>Het is medewerkers verboden om de cliënte of haar gezinsleden onder het mom van ‘zorgverlening’ in hun eigen (bedrijfs)auto te vervoeren.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 11 - BEWAREN VAN GEGEVENS</h3>
                        <ol className="list-decimal pl-6">
                            <li>Kraamzorg Mama bewaart gegevens over de cliënte en de pasgeborene zoals vastgelegd in de overeenkomst, het LIP-formulier, JGZ-overdracht, de urenregistratie en eventuele gezondheidsregistraties. Dit is noodzakelijk voor zorgverlening, kwaliteitstoetsing.</li>
                            <li>Bij beëindiging van de overeenkomst blijven deze gegevens, voor zover noodzakelijk, bewaard conform de daarvoor geldende bewaartermijnen. De cliënte kan een kopie van haar gegevens opvragen. De bewaartermijn is gebaseerd op doelbinding en proportionaliteit; gegevens worden niet langer bewaard dan noodzakelijk.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 12 - GEGEVENSVERSTREKKING AAN DERDEN</h3>
                        <ol className="list-decimal pl-6">
                            <li>Zonder schriftelijke toestemming van de cliënte deelt Kraamzorg Mama geen gegevens over de cliënte en de pasgeborene met derden, behalve indien dit noodzakelijk is op verplichtingen (bijvoorbeeld de meldcode kindermishandeling) of de veiligheid van kind/gezin in het geding is.</li>
                            <li>Onder "derden" worden niet verstaan de verloskundige en anderen die in opdracht van Kraamzorg Mama betrokken zijn bij de levering van kraamzorg, voor zover noodzakelijk voor hun werkzaamheden.</li>
                            <li>Na overlijden van cliënte en/of pasgeborene kan, onder specifieke omstandigheden en conform geldende regelgeving, inzage in de gegevens aan nabestaanden worden gegeven, indien er (veronderstelde) toestemming bestaat.</li>
                            <li>Alle betrokken medewerkers, zowel kraamverzorgenden als administratief personeel, zijn gehouden aan een geheimhoudingsplicht.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 13 - KWALITEIT EN VEILIGHEID</h3>
                        <ol className="list-decimal pl-6">
                            <li>Kraamzorg Mama levert kraamzorg met inachtneming van erkende normen voor verantwoorde kraamzorg, zoals vastgelegd in richtlijnen, protocollen en het LIP, en in samenspraak met representatieve organisaties.</li>
                            <li>Kraamverzorgenden zijn bevoegd, bekwaam en handelen volgens professionele standaarden. Afwijkingen van richtlijnen worden gemotiveerd vastgelegd en aan de cliënte uitgelegd.</li>
                            <li>Kraamverzorgenden in opleiding werken onder supervisie van een praktijkbegeleider.</li>
                            <li>Kraamzorg Mama zorgt voor continuïteit van de kraamzorg en neemt zo nodig maatregelen om uitval op te vangen.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 14 - VEILIGHEID</h3>
                        <p>De kraamzorgaanbieder gebruikt deugdelijk, schoon en veilig materiaal bij de uitvoering van kraamzorg. De cliënte dient te zorgen voor een veilige werkomgeving conform de instructies van Kraamzorg Mama (bijvoorbeeld door het plaatsen van bedverhogers).</p>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 15 - AFSTEMMING BIJ MEERDERE ZORGVERLENERS</h3>
                        <p>Als meerdere zorgverleners namens Kraamzorg Mama bij de cliënte betrokken zijn, zorgt Kraamzorg Mama voor goede onderlinge afstemming, overdracht en duidelijke taakverdeling, met inachtneming van de wensen en ervaringen van de cliënte.</p>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 16 - INCIDENTEN</h3>
                        <ol className="list-decimal pl-6">
                            <li>Bij een incident informeert Kraamzorg Mama de cliënte zo spoedig mogelijk over aard, oorzaak en genomen maatregelen.</li>
                            <li>Indien er gevolgen zijn voor de gezondheid van moeder en/of kind, wordt meteen overleg gepleegd met de verloskundige.</li>
                            <li>De kraamverzorgende treft in overleg met de verloskundige adequate maatregelen om eventuele schade te beperken.</li>
                            <li>Indien direct ingrijpen nodig is, handelt de kraamverzorgende onmiddellijk en meldt dit zo snel mogelijk aan de verloskundige.</li>
                            <li>Incidenten worden geregistreerd en geëvalueerd om herhaling te voorkomen.</li>
                        </ol>
                    </article>  

                    <article>
                        <h3 className="font-bold">ARTIKEL 17 - ZORG VOOR PERSOONLIJKE EIGENDOMMEN</h3>
                        <p>Kraamzorg Mama garandeert dat medewerkers zorgvuldig omgaan met eigendommen van de cliënte en haar gezin. Bij schade als gevolg van handelen door medewerkers geldt de schaderegeling als beschreven in artikel 18 lid 7.</p>
                    </article>  

                    <article>
                        <h3 className="font-bold">ARTIKEL 18 - VERPLICHTINGEN VAN DE CLIËNTE</h3>
                        <ol className="list-decimal pl-6">
                            <li>De cliënte legitimeert zich op verzoek met een geldig identiteitsdocument.</li>
                            <li>De cliënte verstrekt naar beste weten de benodigde informatie voor een goede uitvoering van de kraamzorg.</li>
                            <li>De cliënte onthoudt zich van agressief, discriminerend, intimiderend of ander ongewenst gedrag. Zij spant zich in dat gezinsleden en bezoekers zich hier ook van onthouden.</li>
                            <li>De cliënte zorgt voor omstandigheden die voldoen aan arbeidsomstandigheden- en hygiëneregels, inclusief het treffen van noodzakelijke voorzieningen (zoals bedverhogers).</li>
                            <li>De cliënte biedt de kraamverzorgende en andere medewerkers de gelegenheid hun taken uit te voeren conform het kraamzorgplan.</li>
                            <li>De cliënte stelt Kraamzorg Mama op de hoogte indien zij parallel kraamzorg of diensten afneemt van een andere aanbieder.</li>
                            <li>De cliënte meldt schade veroorzaakt door de kraamverzorgende binnen 72 uur na de schadegebeurtenis schriftelijk aan Kraamzorg Mama. Latere meldingen worden niet in behandeling genomen. Een eigen risico van € 50,- is van toepassing. De schadevergoeding is gebaseerd op de hoogste dagwaarde van het beschadigde product.</li>
                            <li>De cliënte accepteert dat Kraamzorg Mama geen rekening kan houden met voorkeuren omtrent leeftijd, geslacht, levensbeschouwing, huidskleur of seksuele geaardheid van de kraamverzorgende. Discriminatoire weigering van medewerkers is niet toegestaan.</li>
                            <li>De cliënte draagt zorg voor het veilig opbergen van waardevolle bezittingen, zoals kostbare sieraden of andere waardevolle voorwerpen, in een afgesloten of anderszins beveiligde ruimte. Kraamzorg Mama is niet aansprakelijk voor verlies, diefstal of beschadiging van dergelijke waardevolle eigendommen van de cliënte of haar gezinsleden.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 19 - BETALING</h3>
                        <ol className="list-decimal pl-6">
                            <li>De cliënte is de overeengekomen kosten verschuldigd (inclusief eigen bijdrage en eventuele aanvullende diensten) voor zover deze niet rechtstreeks door de zorgverzekeraar worden voldaan.</li>
                            <li>Kraamzorg Mama verstrekt bij aanvullende kosten een gespecificeerde factuur.</li>
                            <li>Indien betaling niet binnen 14 dagen plaatsvindt, stuurt Kraamzorg Mama een betalingsherinnering. Na het verstrijken van deze termijn kunnen incassokosten worden doorbelast.</li>
                            <li>Parkeerkosten komen voor rekening van de cliënte, tenzij zij een geschikte gratis parkeerplaats of parkeerkaart verstrekt.</li>
                            <li>Indien de cliënte heeft deelgenomen aan een inschrijfactie met cadeau of waardebon, kunnen de kosten hiervan bij de cliënte in rekening worden gebracht indien zij niet aan de voorwaarden voldoet.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 20 - BEËINDIGING OVEREENKOMST</h3>
                        <ol className="list-decimal pl-6">
                            <li>
                                De overeenkomst eindigt automatisch indien:<br />
                                a. De cliënte of pasgeborene langer dan 10 dagen in het ziekenhuis verblijft zonder noodzaak tot kraamzorg, tenzij aanvullend verzekerd voor uitgestelde kraamzorg;<br />
                                b. Bij wederzijds goedvinden;<br />
                                c. Bij overlijden van de cliënte of pasgeborene als er geen behoefte is aan kraamzorg.
                            </li>
                            <li>Indien de cliënte de overeenkomst opzegt zonder geldige reden, kunnen annuleringskosten van € 69,- worden gerekend.</li>
                            <li>Annulering gebeurt schriftelijk via e-mail of brief.</li>
                        </ol>
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 21 - OPZEGGING DOOR DE KRAAMZORGAANBIEDER</h3>
                        <ol className="list-decimal pl-6">
                            <li>Kraamzorg Mama kan de overeenkomst alleen om gewichtige redenen opzeggen, zoals ernstige bedreiging, intimidatie, onveilige werkomstandigheden, of structurele niet-nakoming door de cliënte.</li>
                            <li>
                                Opzegging vindt schriftelijk plaats, nadat:<br />
                                a. De gronden met de cliënte zijn besproken;<br />
                                b. Een passend alternatief is voorgelegd;<br />
                                c. De cliënte is gewezen op de mogelijkheid tot klachtenindiening.
                            </li>
                            <li>Kraamzorg Mama past zorgvuldigheid toe en waarschuwt in beginsel voordat tot opzegging wordt overgegaan.</li>
                        </ol>

                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 22 - KLACHTENREGELING</h3>
                        <ol className="list-decimal pl-6">
                            <li>Kraamzorg Mama hanteert een interne klachtenprocedure gebaseerd op de eisen van de Kwaliteit, Klachten Zorg.</li>
                            <li>Er is een onafhankelijke klachtenfunctionaris beschikbaar die de cliënte kan bijstaan bij het indienen en afhandelen van een klacht.</li>
                            <li>De klachtenregeling is duidelijk zichtbaar op de website en op verzoek in papieren vorm verkrijgbaar.</li>
                        </ol>
                    </article>  

                    <article>
                        <h3 className="font-bold">ARTIKEL 23 - GESCHILLENREGELING</h3>
                        <ol className="list-decimal pl-6">
                            <li>Indien een klacht niet naar tevredenheid wordt afgehandeld, kan deze als geschil worden voorgelegd aan de genoemde geschillencommissie.</li>
                            <li>De geschillencommissie kan claims en klachten behandelen en afhandelen.</li>
                            <li>De uitspraken van de geschillencommissie zijn bindend.</li>
                        </ol>
                            
                    </article>

                    <article>
                        <h3 className="font-bold">ARTIKEL 24 - WIJZIGING ALGEMENE VOORWAARDEN</h3>
                        <p>Deze algemene voorwaarden kunnen slechts worden gewijzigd in overleg tussen Bo Geboortezorg, de Consumentenbond, LOC Zeggenschap in de zorg en de Patiëntenfederatie Nederland. Kraamzorg Mama informeert de cliënte tijdig over eventuele wijzigingen.</p>
                    </article>

                    <article>
                        <h3 className="font-bold">SLOTBEPALING</h3>
                        <p>In gevallen waarin deze algemene voorwaarden niet voorzien, zullen Kraamzorg Mama en de cliënte in overleg tot een redelijke oplossing komen, waarbij het belang van moeder en kind voorop staat. Deze algemene voorwaarden beogen een zo volledig mogelijke bescherming en duidelijkheid te bieden, zonder afbreuk te doen aan redelijkheid en billijkheid.</p>
                    </article>

                </div>

            </div>
        </div>

        <AanvragenAndPostcodeCheck />




        <Footer />

		<Cookie />

    </Fragment>
  );
}


function PageReviews() {

    const totalReviews = 8;

    const [loadedReviews, setLoadedReviews] = useState([
        {},{},{}
    ])

    const moreReviews = () => {
        if(loadedReviews.length < totalReviews) {
            setLoadedReviews([...loadedReviews, {}, {}, {}]);
        }
    }


    return <Fragment>

        <div className="mt-8 px-4 lg:px-32 grid grid-col-1 gap-8">
                  
            {loadedReviews.map((x) => {
                return <PageReview />
            })}

        </div>

        { loadedReviews.length < totalReviews && <div className="mt-8 flex justify-center content-center px-4">
            <div className="border-pink hover:bg-grey hover:bg-opacity-10 border py-2 px-16 rounded-lg text-pink flex hover:cursor-pointer w-full lg:w-auto content-center justify-center" onClick={() => moreReviews()}>
                <div className="text-lg font-bold">Lees meer</div><img alt="pijl naar beneden" className="pl-2" src="/assets/images/icon-arrow-down-pink.svg" />
            </div>
        </div> }

    </Fragment>


}

function PageReview() {

    return <div className={`bg-white border border-solid text-base tracking-normal md:block rounded-xl`}>
        <div className="flex justify-between">
            <div className="p-3 w-min-content">
                <img alt="foto persoon" src="/assets/images/test-review-profile2.png" /> 
            </div>
            <div className="py-3 bg-gray flex-auto">
                <h3 className="font-bold">1. Nola van donselaar</h3>
                <img alt="ster" key={"el" + (1).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (2).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (3).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (4).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (5).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
            </div>
            <div className="w-min-content p-3">
                <img alt="google" src="/assets/images/google.svg" />
            </div>
        </div>
        <div className="p-3 text-lg">
            Amazing customer support and fantastic product to showcase your reviews on your website. Highy recommended.
            Amazing customer support and fantastic product to showcase your reviews on your website. Highy recommended.
            Amazing customer support and fantastic product to showcase your reviews on your website. Highy recommended.
        </div>
    </div>

}



